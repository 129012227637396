import React, { Component } from "react";
// import MetaTags from 'react-meta-tags';


import "./Jobs.scss";
import Button from "../../UIComponents/Button";

class Jobs extends Component {

    render() {
        return (

            <div className="Jobs">

                <div className="header-image"></div>

                <div className="Jobs-container">

                    <h3>Jobs</h3>

                    {/* <Button title="Jetz Bewerben" onClick={} /> */}

                    <div className="Jobs-container-item">
                        <h3>Serviceminijob (m/w/d) Frankfurt </h3>
                        <b>Burgstraße, Frankfurt am Main</b>
                        {/* <p>This is the description of the job.</p> */}
                    </div>


                    <div className="Jobs-container-item">
                        <h3>Küchenhilfe (m/w/d) Frankfurt </h3>
                        <b>Burgstraße, Frankfurt am Main</b>
                        {/* <p>This is the description of the job.</p> */}
                    </div>
                </div>



            </div>
        );

    }
}
export default Jobs;
