import React, { Component } from "react";

import TelefonImg from "../../assets/images/telefon.png";
import MailImg from "../../assets/images/mail.png";

import "./Home.scss";

class DetailsSection extends Component {


    render() {
        return (
            <div className="App-section-small">
                <div className="content-container">
                    <h1 className="Alpha-title">Home</h1>
                    <div className="Alpha-Details">
                        <div className="Alpha-Details-item flex  flex-col items-center">
                            <h3>ÖFFNUNGSZEITEN</h3>

                            <div className="Details-content flex flex-1 flex-col items-center just-between">
                                <p><b>Mi - Sa</b> 17:00 - 23:00 Uhr</p>
                                <span>(Küche bis 22:00 Uhr)</span>
                                <br />
                                <p><b>Sonntag</b>  16:00 - 21:30 Uhr</p>
                                <span>(Küche bis 21:00 Uhr)</span>
                                <br />
                                <p><b>Montag und Dienstag</b> Ruhetag</p>
                                <span>An Feiertagen geschlossen</span>
                            </div>


                        </div>
                        <div className="Alpha-Details-item flex flex-col just-between items-center">
                            <h3 className="flex">Reservierungen</h3>

                            <span>Für Reservierungen können Sie uns telefonisch oder per E-mail erreichen.</span>

                            <div className="flex flex-1 flex-row">
                                <div>
                                    <a href="tel:06920017594" className="Details-button-containter">
                                        <img src={TelefonImg} alt="telephone icon" className="Button-icon" />
                                        <a href="tel:06920017594">06920017594</a>
                                        <b className="Button-label">Telefon</b>
                                    </a>
                                </div>
                                <div >
                                    <a href="mailto:tavernalphaffm@gmail.com" className="Details-button-containter">
                                        <img src={MailImg} alt="email icon" className="Button-icon" />
                                        <a href="mailto:tavernalphaffm@gmail.com">Zu E-mail</a>
                                        <b className="Button-label">E-mail</b>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

export default DetailsSection;