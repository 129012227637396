// styles 
import "../../general.scss";
import "../../App.scss";
import "./Home.scss";

// components 
import DetailsSection from "./DetailsSection";
import SpeisenSection from "./SpeisenSection";
import GallerySection from "./GallerySection";
import ContactSection from "./ContactSection";
import HeaderSection from "./Header";
import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";

function Home(props) {
    useEffect(() => {
        let currentLocation = window.location.href;
        const hasCommentAnchor = currentLocation.includes("/#");
        if (hasCommentAnchor) {
            const anchorCommentId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
            const anchorComment = document.getElementById(anchorCommentId);
            console.log(anchorCommentId);
            if (anchorComment) {
                anchorComment.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [])
    return (
        <> <MetaTags>
            <title>Home - Taverna Alpha</title>
        </MetaTags>
            <div className="Home">
                <HeaderSection />
                <DetailsSection />
                <SpeisenSection />
                <GallerySection id="gallery" handlePreviewClick={props.handlePreviewClick} />
                <ContactSection id="kontakt" />
            </div></>
    );
}

export default Home;
