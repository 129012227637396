import * as React from "react";
import { Link } from "react-router-dom";

function Navigation({ hide = false }) {
    return (
        <>
            {hide ? null :
                (<div className="Navigation">
                    {/* <a to="Home">Homez</Link> */}
                    <a href="/">Home</a>
                    <a href="/menu">Speisekarte</a>
                    <a href="/jobs">Jobs</a>
                </div>)
            }
        </>);
}

export default Navigation;
