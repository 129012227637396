import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


// styles 
import "./general.scss";
import "./App.scss";
import "./containers/General/Sidebar.scss";
// components 
import Home from "./containers/Home";
import Menu from "./containers/Menu";
import { useState } from "react";
import Footer from "./containers/General/Footer";
import Datenschutz from "./containers/General/Datenschutz";
import Impressum from "./containers/General/Impressum";
import close from "./assets/images/close.png";
import bookIcon from "./assets/images/book icon.png";
import Jobs from "./containers/Jobs/Jobs";
import Navigation from "./containers/General/Navigation";

function App() {
    const [active, setActive] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [pathname, setPathname] = useState("home");

    // useEffect(() => {
    //     setPathname(window.location.pathname);
    // }, []);

    const handleClick = () => {
        setActive(!active);
    }

    const handlePreviewClick = (src) => {
        console.log(src);
        setSelectedImage(src);
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }
    return (
        <>
            <div className={openModal ? "Modal" : "hidden"}>
                <div className="Modal-content">
                    <img src={selectedImage} className="Selected-img-modal" />
                    <img onClick={handleCloseModal} src={close} className="close" />

                </div>
            </div>
            <div className={openModal ? "App modal-mode" : "App"}>
                {/* <Navigation goTo={this.goTo} tab={tab} /> */}
                <main className={active ? "move-to-left" : ""}>
                    <Navigation hide={window.location.pathname === "/"} />
                    <Router>
                        <Routes>
                            <Route path="/" element={<Home handlePreviewClick={handlePreviewClick} />} />
                            <Route path="/menu" element={<Menu />} />
                            <Route path="/jobs" element={<Jobs />} />
                            <Route path="/datenschutz" element={<Datenschutz />} />
                            <Route path="/impressum" element={<Impressum />} />
                        </Routes>
                    </Router>
                    <Footer />
                </main>

                <div onClick={handleClick} className="nav-right visible-xs">
                    <div className={active ? "button active" : "button"} id="btn">
                        <div className="bar top"></div>
                        <div className="bar middle"></div>
                        <div className="bar bottom"></div>
                    </div>
                </div>
                <div className="sidebar">
                    <ul className="sidebar-list">
                        <li className={active ? "sidebar-item active" : "sidebar-item"}><a href="/" className="sidebar-anchor">Home</a></li>
                        <li className={active ? "sidebar-item active" : "sidebar-item"}><a href="/menu" className="sidebar-anchor">Speisekarte</a></li>
                        <li className={active ? "sidebar-item active" : "sidebar-item"}><a onClick={handleClick} href="/#gallery" className="sidebar-anchor">Gallerie</a></li>
                        <li className={active ? "sidebar-item active" : "sidebar-item"}><a onClick={handleClick} href="/#kontakt" className="sidebar-anchor">Kontakt</a></li>
                        <li className={active ? "sidebar-item active" : "sidebar-item"}><a href="/Taverna Alpha Allergen Menu.pdf" className="sidebar-anchor">Allergenkarte</a></li>
                        <li className={active ? "sidebar-item active" : "sidebar-item"}><a href="/Taverna Alpha Menu.pdf" className="sidebar-anchor">
                            <img src={bookIcon} />English Menu</a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default App;
