import React, { Component } from "react";


import AlphaLogo from "../../assets/images/alpha-logo-big.png";
import UpBorder from "../../assets/images/white-up-border.png";

import "./Home.scss";

class HeaderSection extends Component {


    render() {
        return (
            <div className="Home-header">
                <div className='Home-header-container flex flex-1 content-container'>
                    <div className="flex flex-70 flex-col items-center just-center">
                        <img src={AlphaLogo} className="AlphaLogo-img" alt="Alpha logo black" />
                    </div>
                    <div className="flex items-center just-center">
                        <div className="flex flex-1 flex-col items-center just-center Announcement-container">
                            {/* <b>Wir sind vom 29.07.2024 bis einschließlich 20.08.2024 geschlossen. Ab dem 21.08.2024 sind wir wieder da! Zum reservieren gerne eine Email schicken! <br /><br /><br />Taverna Alpha</b> */}
                        </div>
                    </div>
                </div>
                <div className="Bottom-Border">
                    <img src={UpBorder} className="Border-img" alt="Brush border bottom" />
                </div>
            </div>
        );
    }
}

export default HeaderSection;