import React, { useEffect, useRef, useState } from "react";
import { useInView } from 'react-intersection-observer';
import { isMobile, isTablet } from "react-device-detect";

import "./Menu.scss";
import MenuItems from "../../data/menu-items.json";
import VeganIcon from "../../assets/images/vegan-icon.png";
import { MetaTags } from "react-meta-tags";
// import { MetaTags } from "react-meta-tags"; 
const Menu = () => {

    const { ref: vorspeisenRef, inView: vorspeisenIsVisible } = useInView({ threshold: 0.3 });
    const { ref: salatRef, inView: salatIsVisible } = useInView({ threshold: 0.5 });
    const { ref: fleischRef, inView: fleischIsVisible } = useInView({ threshold: 0.7 });
    const { ref: fischRef, inView: fischIsVisible } = useInView({ threshold: 0.5 });
    const { ref: veganRef, inView: veganIsVisible } = useInView({ threshold: 0.5 });
    const { ref: extrasRef, inView: extrasIsVisible } = useInView({ threshold: 1 });
    const { ref: nachtischRef, inView: nachtischIsVisible } = useInView({ threshold: 0.8 });
    const { ref: getrankeRef, inView: getrankeIsVisible } = useInView({ threshold: 0.3 });


    const inputRef = useRef();
    const extrasButtonRef = useRef();
    const nachtischButtonRef = useRef();
    const vorspeisenButtonRef = useRef();
    const salateButtonRef = useRef();
    const fleischButtonRef = useRef();
    const fischButtonRef = useRef();
    const getrankeButtonRef = useRef();
    const veganButtonRef = useRef();



    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("did MOUNT");
    }, []);

    useEffect(() => {

        // check if mobile
        if (isMobile || isTablet) {
            // check which is rn visible and scroll to it

            let scrollRef = inputRef?.current;;
            let activeRef = vorspeisenButtonRef?.current;;
            let right = 0;
            if (salatIsVisible) {

                activeRef = salateButtonRef?.current;
            } else if (fleischIsVisible) {

                activeRef = fleischButtonRef?.current;
            } else if (fischIsVisible) {

                activeRef = fischButtonRef?.current;
            } else if (extrasIsVisible) {

                activeRef = extrasButtonRef?.current;
            } else if (nachtischIsVisible) {

                activeRef = nachtischButtonRef?.current;
            } else if (getrankeIsVisible) {

                activeRef = getrankeButtonRef?.current;
            }

            right = activeRef.offsetLeft - scrollRef.offsetLeft - (scrollRef.offsetWidth / 2) + (activeRef.offsetWidth / 2);
            if (veganIsVisible) {
                right = veganButtonRef.current.offsetLeft - scrollRef.offsetLeft - (scrollRef.offsetWidth / 2) + (veganButtonRef.current.offsetWidth / 2);
                right += -15;
            }
            console.log("active", activeRef);
            console.log("current right: " + right);
            inputRef.current.scrollLeft += right;
        };

    }, [vorspeisenIsVisible, salatIsVisible, fleischIsVisible, fischIsVisible, veganIsVisible, extrasIsVisible, nachtischIsVisible, getrankeIsVisible]);


    const renderButtons = () =>
    (
        <>

            <a href="#vorspeisen" ref={vorspeisenButtonRef} className={`menu-section-button ${vorspeisenIsVisible ? "menu-section-button-active" : ""}`} >Vorspeisen</a>
            <a href="#salate" ref={salateButtonRef} className={`menu-section-button ${salatIsVisible ? "menu-section-button-active" : ""}`} >Salate</a>
            <a href="#fleischgerichte" ref={fleischButtonRef} className={`menu-section-button ${fleischIsVisible ? "menu-section-button-active" : ""}`} >Fleischgerichte</a>
            <a href="#fisch" ref={fischButtonRef} className={`menu-section-button ${fischIsVisible ? "menu-section-button-active" : ""}`} >Fisch</a>
            <a href="#vegan" ref={veganButtonRef} className={`menu-section-button ${veganIsVisible ? "menu-section-button-active" : ""}`} >Vegetarisch/Vegan</a>
            <a href="#extras" ref={extrasButtonRef} className={`menu-section-button ${extrasIsVisible ? "menu-section-button-active" : ""}`} >Extras</a>
            <a href="#nachtisch" ref={nachtischButtonRef} className={`menu-section-button ${nachtischIsVisible ? "menu-section-button-active" : ""}`} >Nachtisch</a>
            <a href="#getranke" ref={getrankeButtonRef} className={`menu-section-button ${getrankeIsVisible ? "menu-section-button-active" : ""}`} >Getränke</a>

        </>
    );
    const renderMenuItems = () => MenuItems.map((item) => {
        let refName = vorspeisenRef;
        if (item.id === "salate") {
            refName = salatRef;
        }
        else if (item.id === "fleischgerichte") {
            refName = fleischRef;
        }
        else if (item.id === "fisch") {
            refName = fischRef;
        }
        else if (item.id === "vegan") {
            refName = veganRef;
        }
        else if (item.id === "extras") {
            refName = extrasRef;
        }
        else if (item.id === "nachtisch") {
            refName = nachtischRef;
        }
        else if (item.id === "extras") {
            refName = extrasRef;
        }
        else if (item.id === "getranke") {
            refName = getrankeRef;
        }
        return (
            <div className="menu-section" key={item.id} ref={refName} id={item.id}>

                <div className="menu-title">
                    <div className="line"></div>
                    <h3>{item.label}</h3>
                    <div className="line"></div>
                    <div className="description">{item.description}</div>
                </div>
                {
                    item.items.map(i => (
                        <div className="menu-item" style={{ marginTop: i.moreTopSpace ? "1rem" : "0.5rem" }}>
                            <div className="flex flex-row items-center just-between width-100">
                                <div className="left-content flex flex-row items-center justify-center">
                                    <b className="menu-item-title">{i.name}</b>
                                    {
                                        i.vegan ? <img className="vegan-icon" src={VeganIcon} alt="vegan icon" /> : ""
                                    }

                                </div>

                                <div className="right-content flex flex-row items-center just-between">
                                    <span style={{ marginRight: 10 }}>{i.subName}</span>
                                    <span> {i.price}€</span>
                                </div>
                            </div>
                            {i.description ? <div className="sub-text">{i.description}</div> : ""}
                        </div>
                    ))
                }

            </div>
        )
    });

    const buttons = renderButtons();
    const items = renderMenuItems();
    return (
        <div id="menu"  >

            <MetaTags>
                <title>Menu - Taverna Alpha</title>
                <meta name="robots" content=""></meta>
            </MetaTags>
            <div className="menu-section-buttons" ref={inputRef}>

                {buttons}
                {/* <a href={`#extramenu`} className="menu-section-button">English Menu</a> */}
            </div>
            <div className="menu-body">
                <div className="Menu-main-title">
                    <h1>Speisekarte</h1>
                </div>
                <div className="flex flex-col">
                    <div className="menu-items flex flex-col">
                        {items}
                    </div>
                </div>
                <br />
                <br />
                <br />
                {/* <div className="button flex just-center" id="extramenu">
                        <a href="/Taverna Alpha Allergen Menu.pdf">Allergene Speisekarte (PDF)</a>
                    </div> */}
                <br />
                {/* <div className="button flex just-center">
                        <a href="/Taverna Alpha Menu.pdf">English Menu (PDF)</a>
                    </div> */}
            </div>
        </div>
    );

}
export default Menu;