
import RightArrow from "../assets/images/right-arrow.png";

function Button({ title = "Button", onClick = null }) {

    return (
        <div onClick={onClick} className="Button">
            {title}
            <img src={RightArrow} className="Right-arrow-img" />
        </div>
    )

}

export default Button;