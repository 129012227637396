import React, { Component } from "react";
import MetaTags from 'react-meta-tags';

import "./Static.scss";

class Impressum extends Component {

    render() {
        return (
            <div className="static-container flex flex-col">
                <MetaTags>
                    <title>Impressum - Taverna Alpha</title>
                </MetaTags>
                <h1>
                    Impressum
                </h1>
                <br /><br /><br />

                <h2>Kontakt</h2>
                <b>Taverna Alpha</b>
                <p>Burgstraße 82</p><br />
                <p>60389 Frankfurt am Main</p><br />
                <p>Telefon Nr.: 069 20017594</p><br />
                <p>Email: tavernalphaffm@gmail.com</p><br />
                <p>SteuerNr.: 14 837 682 87</p><br /><br /><br />



                <h2>Verantwortlich für den Inhalt der Website:</h2>
                <b>Queenie Villasin</b>
                <p>kyuvillasin@gmail.com</p><br /><br /><br />


                <h2>Copyright</h2>
                <p>Das Copyright für veröffentlichte, vom Autor selbst erstellte Texte und Bilder bleibt allein beim Autor der Seiten. Die Vervielfältigung oder Verwendung der Bilder und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.</p><br />

            </div>
        )
    }
}
export default Impressum;