import React, { Component } from "react";

import Image1 from "../../assets/images/gallery1.jpg";
import Image2 from "../../assets/images/gallery2.jpg";
import Image3 from "../../assets/images/gallery3.jpg";
import Image4 from "../../assets/images/gallery4.jpg";
import Image5 from "../../assets/images/gallery5.jpg";
import Image6 from "../../assets/images/gallery6.jpg";
import Image7 from "../../assets/images/gallery7.jpg";
import Image8 from "../../assets/images/gallery8.jpg";
import Image9 from "../../assets/images/gallery9.jpg";
import Image10 from "../../assets/images/gallery10.jpg";
import Image11 from "../../assets/images/gallery11.jpg";

import "./Home.scss";

class GallerySection extends Component {

    clickImage = (src) => {
        this.props.handlePreviewClick(src);
    }

    render() {
        return (
            <div className="App-section-small" id="gallery" >
                <div className="content-container">
                    <div className="Alpha-Gallery">

                        <h1>Gallerie</h1>
                        <h3>Unsere Taverna!</h3>
                        <div className="Alpha-Gallery-grid-container">
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image1)} src={Image1} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image7)} src={Image7} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image2)} src={Image2} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image3)} src={Image3} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image4)} src={Image4} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image5)} src={Image5} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image6)} src={Image6} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image9)} src={Image9} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image8)} src={Image8} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image10)} src={Image10} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                            <div className="Alpha-Gallery-grid-item">
                                <img onClick={() => this.clickImage(Image11)} src={Image11} alt="menu icon" className="Alpha-Gallery-grid-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GallerySection;