import React, { Component } from "react";

import AlphaLogo from "../../assets/images/taverna-alpha-logo.png";
import IGLogo from "../../assets/images/ig-logo.png";
import FBLogo from "../../assets/images/fb-logo.png";
import GoogleLogo from "../../assets/images/google-logo.png";
import RightArrow from "../../assets/images/right-arrow.png";

import "./Home.scss";

class ContactSection extends Component {
    state = {
        googleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2557.8941139399267!2d8.698352715949948!3d50.12570331859155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0eeacb4160e7%3A0x7d4b7686b89e799b!2sBurgstra%C3%9Fe%2082%2C%2060389%20Frankfurt%20am%20Main!5e0!3m2!1sen!2sde!4v1602707445423!5m2!1sen!2sde"
    }

    render() {
        return (
            <div className="App-section-small-transparent" id="kontakt" >
                <div className="Alpha-Contact">
                    <div className="flex-1 Alpha-Contact-Left">
                        <div>
                            <img src={AlphaLogo} alt="Taverna Alpha black logo" className="Alpha-logo" />
                            <br />
                            <h3>Griechesches Restaurant</h3>
                        </div>

                        <div className="Alpha-address">
                            <h1>Kontakt</h1>
                            <p>Burgstrasse 82</p>
                            <p>60389, Frankfurt am Main</p>
                            <a href="tel:06920017594">06920017594</a><br />
                            <a href="mailto:tavernalphaffm@gmail.com">tavernalphaffm@gmail.com</a>
                        </div>

                        <div className="Alpha-map">
                            <iframe title="Karte zur Taverna Alpha" src={this.state.googleMaps} width="100%" height="300" frameBorder="0" style={{ border: "0" }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>

                        </div>
                    </div>
                    <div className="flex-1 Alpha-Contact-Right">
                        <h3>Socials</h3>
                        <p>Wir freuen uns auf Sie auf unseren socials!</p>

                        <div className="Logos-container">
                            <div className="Logos-item"><a href="https://www.instagram.com/tavernalpha/"><img src={IGLogo} className="Logos-item" /></a></div>
                            <div className="Logos-item"><a href="https://www.facebook.com/tavernalpha/"><img src={FBLogo} className="Logos-item" /></a></div>
                            <div className="Logos-item"> <a href="https://www.google.com/maps/place/Taverna+Alpha,+Burgstra%C3%9Fe+82,+60389+Frankfurt+am+Main/@50.1256999,8.7005414,15z/data=!4m6!3m5!1s0x47bd0f9ea062069b:0x38c69692b96f46ce!8m2!3d50.1256999!4d8.7005414!16s%2Fg%2F11lf_21tgt">
                                <img src={GoogleLogo} className="Logos-item" /></a></div>
                        </div>

                        {/* <div className="Button">
                            Contact Us
                            <img src={RightArrow} className="Right-arrow-img" />
                        </div> */}
                    </div>
                </div>

            </div >
        );
    }
}

export default ContactSection;