import React, { Component } from "react";

import VorspeisenImg from "../../assets/images/Vorspeisenteller.JPG";
import BottomBorder from "../../assets/images/white-bottom-border.png";
import ArrowImg from "../../assets/images/arrow-brush.png";
import MenuImg from "../../assets/images/menu-button.png"

import "./Home.scss";

class SpeisenSection extends Component {


    render() {
        return (
            <div className="Alpha-Speisen App-section-small">
                <div className="pos-abs Up-Border">
                    <img src={BottomBorder} className="Border-img" alt="Brush border top" />
                </div>
                <div className="content-container">
                    <div className="Alpha-Speisen-content ">
                        <div>
                            <img src={VorspeisenImg} className="Speisen-img" alt="Vorspeisenteller alpha" />
                        </div>

                        <div className="Alpha-Speisen-Right">
                            <b>Ihre griechischen Lieblingsgerichte auch in vegan!</b>
                            <div className="Alpha-Speisen-button-container">
                                <b className="Speisen-label">SPEISEKARTE</b>
                                <a href="/menu">
                                    <div className="pos-rel">
                                        <img src={MenuImg} alt="menu icon" className="Speisen-button" />
                                        <img src={ArrowImg} alt="arrow icon" className="Speisen-arrow" />
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SpeisenSection;