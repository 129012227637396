
import "./Static.scss";


function Footer() {
    return (
        <div className="Footer">
            <b>© Copyright 2023 Taverna Alpha</b>
            <a href="/datenschutz"><b>Datenschutz</b></a>
            <a href="/impressum"><b>Impressum</b></a>
        </div>
    );
}

export default Footer;